<template>
  <div class="pa-0 ma-0 fill-height">
    <v-sheet class="mx-12 mt-12" min-height="100%">
      <h1 class="headline mb-6">About Us</h1>
      <h2 class="display-2 mb-6">We live to enliven others</h2>
      <p class="display-1 mb-6 text-justify">
        We're here to promote North Sulawesi via digital means.<br />
        We're here to show the world our sights, our culture, even our businesses.<br />
        We're here to bring supply and demand together.<br />
        We're here so that North Sulawesi is worthy of global relevance.
      </p>
    </v-sheet>
    <amborjo-footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    'amborjo-footer': Footer
  },

  beforeCreate () {
    document.title = 'About Us - AmborJo'
  }
}
</script>
